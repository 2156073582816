import { FaCheck } from "react-icons/fa";
import { Database } from "../DB/Database";

const About = () => {
    
    const className = {
      container:
        "w-full h-full py-20 px-5 md:px-8 xl:px-16 font-poppins place-items-center gap-10 grid grid-cols-1 xl:grid-cols-2 bg-purple-black ",
      box: "w-full h-full space-y-12 xl:space-y-9 text-white grid place-items-center xl:place-items-stretch text-center xl:text-start",
      titleBox: "space-y-4",
      img: "w-full h-fit",
      imgMobBox: "block xl:hidden w-[250px] h-fit overflow-hidden rounded-[50%]",
      
      imgDeskBox:
        "hidden xl:block 2xl:w-[600px] 2xl:h-[600px] overflow-hidden rounded-[50%]",
      sectTitle: "text-base md:text-lg font-medium text-white-green",
      title: "inline-block font-bold text-2xl sm:text-4xl md:text-5xl 2xl:text-6xl",
      highlight: "text-white-green",
      paras: "2xltext-lg",
      horizonList: "flex flex-col md:flex-row items-start md:items-center justify-center xl:justify-start gap-10 xl:w-full",
      verticalList: "space-y-3",
      list: "flex items-center gap-4 font-medium text-lg",
      check: "text-white-green",
      contactBox:
        "w-fit flex flex-col md:flex-row items-center gap-12 md:gap-7 justify-start p-7 rounded-md border border-white",
      contact: "flex items-center gap-2",
      logo: "w-fit h-fit p-3 rounded-full grid place-items-center bg-white-green text-teks-black",
      subContact: "text-sm",
    };

    const Data = Database.about;

    return (
      <div className={className.container} id="about">
        <div className={className.box}>
          <h4 className={className.sectTitle}>{Data.subtitle}</h4>

          <div className={className.imgMobBox}>
            <img className={className.img} src={Data.img} alt="Me" />
          </div>

          <div className={className.titleBox}>
            <h1 className={className.title}>
              {Data.title1}{" "}
              <span className={className.highlight}>{Data.highlight1}</span>
              {Data.title2}
            </h1>
            <br />
            <h1 className={className.title}>
              {Data.title3}{" "}
              <span className={className.highlight}>{Data.highlight2}</span>{" "}
              {Data.title4}
            </h1>
          </div>
          <p className={className.paras}>{Data.paras}</p>
          <div className={className.horizonList}>
            <ul className={className.verticalList}>
              {Data.list1.map((data, idx) => (
                <li key={idx} className={className.list}>
                  <FaCheck className={className.check} /> {data.title}
                </li>
              ))}
            </ul>
            <ul className={className.verticalList}>
              {Data.list2.map((data, idx) => (
                <li key={idx} className={className.list}>
                  <FaCheck className={className.check} />
                  {data.title}
                </li>
              ))}
            </ul>
          </div>
          <div className={className.contactBox}>
            {Data.contacts.map((data, idx) => (
              <div className={className.contact}>
                <div className={className.logo}>{data.logo}</div>
                <div>
                  <p className={className.subContact}>{data.subtitle}</p>
                  <h1>{data.content}</h1>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={className.imgDeskBox}>
          <img className={className.img} src={Data.img} alt="Me" />
        </div>
      </div>
    );
}


export default About;