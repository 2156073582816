import Logo from '../../attachments/greenLogo.png'

const Footer = () => {
  return (
    <footer class="bg-gray-900 rounded-lg shadow mt-4">
      <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div class="sm:flex sm:items-center sm:justify-between">
          <a
            href="https://adam-is.my.id"
            class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <img
              src={Logo}
              class="h-8"
              alt="AD Logo"
            />
            <span class="self-center text-2xl font-Josefin flex items-center gap-2 whitespace-nowrap text-white">
              AD <p className="font-thin">Design</p>
            </span>
          </a>
          <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <a href="#about" class="hover:underline me-4 md:me-6">
                About
              </a>
            </li>
            <li>
              <a href="#layanan" class="hover:underline me-4 md:me-6">
                Services
              </a>
            </li>
            <li>
              <a href="#klien" class="hover:underline me-4 md:me-6">
                Portfolio
              </a>
            </li>
            <li>
              <a href="#kontak" class="hover:underline">
                Contact
              </a>
            </li>
          </ul>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2023{" "}
          <a href="https://adam-is.my.id/" class="hover:underline">
            AD Design™
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};


export default Footer;