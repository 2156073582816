import { Database } from "../DB/Database";

const Service = () => {
  const className = {
    container:
      "w-full px-5 md:px-10 py-20 flex flex-col bg-purple-black font-Poppins justify-center items-center gap-12",
    paraBox: "text-center space-y-8",
    subtitle: "font-medium text-lg text-white-green",
    title: "text-3xl md:text-4xl text-white font-medium",
    gridBox: "w-full grid grid-cols-1 xl:grid-cols-3 gap-5 place-items-center",
    card: "border-4 h-full text-center text-white bg-transparent border-white/70 py-6 px-5 flex flex-col items-center justify-center gap-7",
    cardTitle: "text-xl md:text-2xl font-medium",
    cardParas: "font-light text-sm md:text-base"
  };

  const Data = Database.service;

  return (
    <div className={className.container} id="layanan">
      <div className={className.paraBox}>
        <h4 className={className.subtitle}>{Data.subtitle.toUpperCase()}</h4>
        <h1 className={className.title}>{Data.title}</h1>
      </div>

      <div className={className.gridBox}>
        {Data.card.map((data, idx) => {
          return <div className={className.card}>
            <img src={data.img} alt="img"/>
            <h1 className={className.cardTitle}> 
                {data.title}
            </h1>
            <p className={className.cardParas}>
                {data.paras}  
            </p>
          </div>;
        })}
      </div>
    </div>
  );
};

export default Service;
