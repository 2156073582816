import React, { useState } from "react";
import { FaEarlybirds, FaEnvelope, FaInstagram, FaKiwiBird, FaLinkedin, FaPaperPlane, FaPhone, FaWhatsapp } from "react-icons/fa6";

const PhoneTab = () => {
  const className = {
    container:
      "w-full h-full space-y-5 grid place-items-center lg:place-items-stretch",
    link: "flex items-center gap-4 w-fit px-7 py-4 border-x border-t md:border-x-0 md:border-y md:border-l border-white-green hover:bg-gradient-to-b from-[#25d366] to-[#128c7e] text-white",
    linkBox:
      "w-fit md:flex-row flex-col flex items-center gap-5 text-white font-medium",
  };

  return (
    <div className={className.container}>
      <div className={className.linkBox}>
        <a href="https://wa.me/+628561488756" className={className.link}>
          <FaWhatsapp /> <strong>Whatsapp me</strong>
        </a>

        +62 856-1488-756
      </div>
    </div>
  );
}

const EmailTab = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const className = {
    container: "",
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:adamharits25@gmail.com?subject=Newsletter Subscription&body=Email: ${email}%0D%0AMessage: ${encodeURIComponent(
      message
    )}`;
    window.location.href = mailtoLink;

    setEmail("");
    setMessage("");
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-gray-800 text-white rounded-lg shadow-md">
      <h3 className="text-xl text-center font-bold mb-4">Let's Get in Touch</h3>
      <form className="space-y-3" onSubmit={handleSubmit}>
        <input
          type="email"
          className="w-full px-4 py-2 text-black rounded-md focus:outline-none focus:ring-2 focus:ring-white-green"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <textarea
          className="w-full px-4 py-2 text-black rounded-md focus:outline-none focus:ring-2 focus:ring-white-green"
          placeholder="Your message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          type="submit"
          className="w-full px-4 py-2 bg-white-green text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-white-green flex items-center justify-center space-x-2"
        >
          <span>Subscribe</span>
          <FaPaperPlane />
        </button>
      </form>
    </div>
  );
};

const SocialsTab = () => {
  const className = {
    container: "w-full h-full space-y-5 grid place-items-center lg:place-items-stretch",
    link: "flex items-center gap-4 w-fit px-7 py-4 border-x border-t md:border-x-0 md:border-y md:border-l text-white",
    linkBox:
      "w-fit md:flex-row flex-col flex items-center gap-5 text-white font-medium",
  };

  return (
    <div className={className.container}>
      <div className="space-y-5">
        <div className={className.linkBox}>
          <a
            href="https://instagram.com/call_meadams"
            className={`${className.link} border-[#c13584] hover:bg-gradient-to-b from-[#c13584] to-[#405de6]`}
          >
            <FaInstagram /> <strong>Lets Connect! </strong>
          </a>
          @call_meadams
        </div>
        <div className={className.linkBox}>
          <a
            href="https://www.linkedin.com/in/adam-harits-b8222822a"
            className={`${className.link} border-[#2867b2] hover:bg-gradient-to-b from-[#2867b2] to-white/70`}
          >
            <FaLinkedin /> <strong>My projects &gt; </strong>
          </a>
          My Linkedin Profile
        </div>
      </div>
    </div>
  );
};

const Contact = () => {
  const [activeTab, setActiveTab] = useState("Phone");

  const tabs = [
    { name: "Phone", component: <PhoneTab />, icon: <FaPhone /> },
    { name: "Email", component: <EmailTab />, icon: <FaEnvelope /> },
    { name: "Socials", component: <SocialsTab />, icon: <FaEarlybirds /> },
  ];

  return (
    <div className="space-y-10 w-full h-auto" id="kontak">
      <div className="space-y-5 text-center font-Poppins">
        <h4></h4>
      </div>
      <div className="md:flex px-5 md:px-24">
        <ul className="flex-column h-full space-y-4 text-sm font-medium text-gray-400 md:me-4 mb-4 md:mb-0">
          {tabs.map((tab) => (
            <li key={tab.name}>
              <button
                className={`inline-flex items-center pl-4 pr-20 py-3 rounded-lg w-full ${
                  tab.name === activeTab
                    ? "text-black bg-white-green font-medium"
                    : "bg-gray-800 border border-white-green hover:bg-gray-700 font-normal text-white"
                }`}
                onClick={() => setActiveTab(tab.name)}
              >
                <span
                  className={`w-4 h-4 me-2 ${
                    tab.name === activeTab
                      ? "text-black"
                      : "text-white dark:text-gray-400"
                  }`}
                >
                  {tab.icon}
                </span>
                {tab.name}
              </button>
            </li>
          ))}
        </ul>
        <div className="p-6 text-medium text-gray-400 bg-gray-800 rounded-lg w-full">
          {tabs.find((tab) => tab.name === activeTab)?.component}
        </div>
      </div>
    </div>
  );
};

export default Contact;
