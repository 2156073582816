import { Database } from "../DB/Database";

const Skill = () => {
    const className = {
      container:
        "w-full h-full py-20 px-10 bg-light-black text-white font-Poppins lg:px-16 space-y-10 md:space-y-14",
      titleBox: "space-y-5 font-medium text-center",
      subtitle: "text-white-green",
      title: "text-3xl lg:text-4xl ",
      backCard: "pt-3 px-2 rounded-full bg-light-black border-4 border-purple-black",
      cards:
        "w-full h-fit px-2 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 place-items-center gap-5 lg:gap-2 ",
      card: "pt-8 pb-24 px-20 border border-light-black rounded-full flex flex-col justify-center items-center gap-5 bg-purple-black ",
      logo: "w-fit h-fit p-5 bg-white text-purple-black text-6xl rounded-full",
      percentage: "text-3xl md:text-4xl font-bold",
      cardTitle: "text-lg font-medium",
      progressBox: "hidden md:grid w-full grid-cols-1 lg:grid-cols-2 gap-7 p-3"
    };

    const Data = Database.skill;

    return (
      <div className={className.container} id="skill">
        <div className={className.titleBox}>
          <p className={className.subtitle}>{Data.subtitle}</p>
          <h1 className={className.title}>{Data.title}</h1>
        </div>

        <div className={className.cards}>
          {Data.list1.map((data, idx) => (
            <div key={idx} className={className.backCard}>
              <div className={className.card}>
                <div className={`${className.logo} ${data.color}`}>{data.logo}</div>
                <h1 className={className.percentage}>{data.percentage}</h1>
                <p className={className.cardTitle}>{data.title}</p>
              </div>
            </div>
          ))}
        </div>

        <div className={className.progressBox}>
          {Data.list2.map((data, idx) => (
            <div>
              <div className="flex justify-between mb-1">
                <span className="text-base font-medium text-white-green dark:text-white">
                  {data.title}
                </span>
                <span className="text-sm font-medium text-white-green dark:text-white">
                  {data.percentage}
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 group dark:bg-gray-700">
                <div
                  className={`bg-white-green border-green-700 border-r-2 h-2.5 rounded-full lg:group-hover:w-full transition-all duration-200 ${data.percentBar}`}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
}

export default Skill;