import { Database } from "../DB/Database";

const ShowCase = () => {
    const className = {
      container: "w-full text-white font-Poppins h-full py-20 px-10 space-y-5 ",
      titleBox: "space-y-7 text-center",
      subtitle: "text-white-green font-medium text-lg",
      title: "text-3xl lg:text-4xl",
      gridBox:
        "w-full h-full grid grid-cols-1 md:grid-cols-2 place-items-center lg:grid-cols-3 gap-10 px-6 py-2",
      card: "space-y-10 p-4 rounded-lg w-full h-full border-2 border-white/25",
      imgBox: "w-full flex items-center p-10 justify-center h-[250px] rounded-md bg-white",
      img: "",
      cardTitle: "text-xl font-medium mb-3",
      cardLink: "text-white-green inline-block"
    };

    const Data = Database.showcase;

    return (
      <div className={className.container} id="klien">
        <div className={className.titleBox}>
          <p className={className.subtitle}>{Data.subtitle}</p>
          <h1 className={className.title}>{Data.title}</h1>
        </div>

        <div className={className.gridBox}>
          {Data.cards.map((data, idx) => (
            <div key={idx} className={className.card}>
              <div className={className.imgBox}>
                <img src={data.img} alt="" className={data.size} />
              </div>

              <div>
                <h1 className={className.cardTitle}>{data.title}</h1>
                <a href={data.link} className={className.cardLink}>
                  Visit Website
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
}

export default ShowCase;