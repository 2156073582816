import Footer  from './components/Contents/Footer';
import './App.css';
import About from './components/Contents/About';
import Home from './components/Contents/Home';
import Pricing from './components/Contents/Pricing';
import Service from './components/Contents/Service';
import ShowCase from './components/Contents/Showcase';
import Skill from './components/Contents/Skill';
import Navbar from './components/Navbar';
import Contact from './components/Contents/Contact';

function App() {
  return (
    <div className="overflow-x-hidden bg-purple-black">
      <Navbar/>
      <Home />
      <Service />
      <About />
      <Skill />
      <ShowCase />
      <Pricing />
      <Contact />

      <Footer />
    </div>
  );
}

export default App;
