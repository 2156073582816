import { FaCheck } from "react-icons/fa";
import { Database } from "../DB/Database";

const Pricing = () => {
    const classname = {
      container: "w-full h-full px-10 xl:px-20 py-10 space-y-10 text-white font-Poppins",
      box: "w-full text-center space-y-3",
      header: "text-xl text-white-green font-bold",
      title: "text-3xl xl:text-5xl font-bold text-white",
      cardGrid: "w-full grid grid-cols-1 lg:grid-cols-3 gap-5 p-3",
      card: "space-y-8 border rounded-md grid place-items-center text-center px-4 py-7 ",
      price: "text-2xl md:text-3xl font-bold my-3",
      listparent: "w-fit space-y-4 text-start list-none",
      list: "flex items-center text-lg justify-start text-white gap-3",
      link: "block w-fit h-fit px-6 py-3 rounded-md bg-white-green text-black hover:text-white hover:bgwhite"
    };

    const Data = Database.pricing;

    return (
        <div className={classname.container} id="harga">
            <div className={classname.box}>
                <p className={classname.header}>
                    {Data.header}
                </p>
                <h1 className={classname.title}>
                    {Data.title}
                </h1>
            </div>
            <div className={classname.cardGrid}>
                {Data.cards.map((data, index) => {
                    return (
                        <div className={`${classname.card} ${index === 1 ? "border-white-green" : "border-white"}`}>
                            <h1 className={classname.header}>
                                {data.title}
                            </h1>
                            <p>
                                {data.desc}
                            </p>
                            <h3 className={classname.price}>
                                {data.price}
                            </h3>
                            <ul className={classname.listparent}>
                                {data.list.map((data2, index2) => {
                                    return <li key={index2} className={classname.list}>
                                      <FaCheck className="text-white-green"/> {data2.title}
                                    </li>;
                                })}
                            </ul>

                            <a href={data.buttonLink} target="_blank" className={classname.link}>
                                Choose this Package
                            </a>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Pricing;