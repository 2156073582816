import Hero from '../../attachments/Hero.png';
import Code from '../../attachments/Code.png';
import Design from "../../attachments/Design.png";
import AppDev from "../../attachments/AppCode.png";
import { FaEnvelope, FaHtml5, FaNodeJs, FaPhone, FaReact } from 'react-icons/fa';
import { SiMysql } from 'react-icons/si';
import me from '../../attachments/idonno.jpg';
import tomsAgency from '../../attachments/TomsAgency.png';
import rumahHypno from '../../attachments/RumahHypno.png';
import lyfe from '../../attachments/LogoLyfeArch.png';
import mTravel from '../../attachments/LogoMtravel.png';
import NftMarket from '../../attachments/LogoNFT.png';
import Ismi from '../../attachments/LogoIsmi.png';


export const Database = {
  home: {
    headtitle1: "Hi, I'm Adam!",
    headtitle2: "I'm A ",
    highlight: "Web Developer",
    subtitle:
      "I’m a dedicated web developer committed to creating stunning online experiences. With expertise in responsive design, frontend development, and backend integration, I'm here to help bring your website vision to life.",

    button1: "Download CV",
    button2: "",
    img: Hero,
  },

  service: {
    subtitle: "My Service",
    title: "Crafting Narratives Through Innovative Technology",

    card: [
      {
        img: Code,
        title: "WEB DEVELOPMENT",
        paras:
          "In web development, I specialize in delivering cutting-edge solutions tailored to your unique needs. From responsive design to dynamic frontend and advanced backend integration.",
      },
      {
        img: Design,
        title: "LOGO DESIGN",
        paras:
          "Our logo is crafted with a modern and minimalist concept to reflect expertise and innovation in the technology industry. The design is dominated by symbols depicting connectivity, and progress.",
      },
      {
        img: AppDev,
        title: "APP DEVELOPMENT",
        paras:
          'In app development (or as we called " Native Developer "), we offer innovative and customizable solutions to meet your unique needs. With extensive experience in mobile and web app development.',
      },
    ],
  },

  about: {
    subtitle: "About me",
    title1: "Crafting",
    highlight1: " Digital ",
    title2: "Experiences",
    title3: "With",
    highlight2: " Passion ",
    title4: "and Precision",

    paras:
      "Welcome to my corner of the web! I'm passionate about crafting digital experiences that resonate with users and leave a lasting impact. With a keen eye for detail and a commitment to excellence, I specialize in turning ideas into dynamic and functional websites.",

    list1: [
      {
        title: "Web Development",
      },
      {
        title: "Android Development",
      },
    ],

    list2: [
      {
        title: "Logo Design",
      },
      {
        title: "UI/UX Designer",
      },
    ],

    contacts: [
      {
        logo: <FaEnvelope />,
        subtitle: "My Email",
        content: "adamharits25@gmail.com",
      },
      {
        logo: <FaPhone />,
        subtitle: "My Phone",
        content: "+62 856-1488-756",
      },
    ],

    img: me,
  },

  skill: {
    subtitle: "My Skills",
    title: "My Expertise And Skills In The Progress Bar",
    list1: [
      {
        logo: <FaHtml5 />,
        percentage: "67%",
        title: "HTML",
        color: "text-orange-500",
      },
      {
        logo: <FaReact />,
        percentage: "80%",
        title: "React",
        color: "text-blue-500",
      },
      {
        logo: <SiMysql />,
        percentage: "65%",
        title: "MySQL",
        color: "text-gray-800",
      },
      {
        logo: <FaNodeJs />,
        percentage: "40%",
        title: "NodeJS",
        color: "text-green-500",
      },
    ],
    list2: [
      {
        title: "CSS & TailwindCSS",
        percentage: "75%",
        percentBar: "w-[75%]",
      },
      {
        title: "Javascript",
        percentage: "80%",
        percentBar: "w-[80%]",
      },
    ],
  },

  showcase: {
    subtitle: "My Portfolio",
    title: "Explore My Portfolio and Unlock Your Inspiration Today!",

    cards: [
      {
        img: tomsAgency,
        title: "Toms Agency",
        link: "https://tomsagency.netlify.app/",
        size: "",
      },
      {
        img: rumahHypno,
        title: "Rumah Hypno",
        link: "https://rumahhypno.netlify.app/",
        size: "",
      },
      {
        img: NftMarket,
        title: "NFT Market",
        link: "https://nft-m-example.netlify.app/",
        size: "",
      },
      {
        img: lyfe,
        title: "Lyfe",
        link: "https://lyfe-arch-example.netlify.app/",
        size: "",
      },
      {
        img: mTravel,
        title: "MTravel",
        link: "https://m-travel-example.netlify.app/",
        size: "",
      },
      {
        img: Ismi,
        title: "Ismi DKI Jakarta",
        link: "https://ismidki-example.netlify.app/",
        size: "h-full",
      },
    ],
  },

  pricing: {
    header: "My Pricing",
    title: "Tailored Solutions to Fit Your Budget and Needs",
    cards: [
      {
        title: "Begineer",
        desc: "Suitable for those who are just starting out and want to build a solid foundation",
        price: "Rp.1.699.000",
        firstPrc: "2.299.000",
        disc: "6%",
        highlight: "0",
        list: [
          {
            title: "Website finished in 1 month",
          },
          {
            title: "Only 3 revisions",
          },
          {
            title: "Non-server (UI/UX only)",
          },
          {
            title: "3-4 page website",
          },
          {
            title: "Communication Priorities",
          },
        ],

        buttonLink:
          "https://wa.me/628561488756?text=Hey%20I'm%20interested%20in%20your%20Beginner%20package",
      },
      {
        title: "Experienced",
        desc: "Suitable for those with experience to enhance skills and projects",
        price: "Rp.2.199.000",
        firstPrc: "2.899.000",
        disc: "7%",
        highlight: "1",
        list: [
          {
            title: "Website ready in 2 weeks",
          },
          {
            title: "7x Max of Revision",
          },
          {
            title: "Non-server (UI/UX only)",
          },
          {
            title: "7-9 page website",
          },
          {
            title: "Communication Priorities",
          },
        ],

        buttonLink:
          "https://wa.me/628561488756?text=Hey%20I'm%20interested%20in%20your%20Experienced%20package",
      },
      {
        title: "Advanced",
        desc: "Suitable for delivering innovative solutions and superior user experiences",
        price: "Rp.2.799.000",
        firstPrc: "3.399.000",
        disc: "6%",
        highlight: "0",
        list: [
          {
            title: "Website finished in 3 months",
          },
          {
            title: "10x Max of Revision",
          },
          {
            title: "Includes Server",
          },
          {
            title: "15-19 page website",
          },
          {
            title: "Communication Priorities",
          },
        ],

        buttonLink:
          "https://wa.me/628561488756?text=Hey%20I'm%20interested%20in%20your%20Advanced%20package",
      },
    ],
  },

  contact: {
    header: "My Contact",
    title: "Let's Get in Touch",

    form: {
      name: {
        placeholder: "Your Name",
      },
      email: {
        placeholder: "Your Email",
      },
      message: {
        placeholder: "Your Message",
      },
    },

    socials: [
      {
        icon: "",
        title: "My Instagram",
      },
      {
        icon: "",
        title: "LinkedIn",
      },
      {
        icon: "",
        title: "",
      },
    ],
  },

  navbar: {
    menus: [
      {
        title: "Home",
        link: "#",
      },
      {
        title: "About me",
        link: "#about",
      },
      {
        title: "Services",
        link: "#layanan",
      },
      {
        title: "Portfolio",
        link: "#klien",
      },
      {
        title: "Contact",
        link: "#kontak",
      },
    ],

    button: {
      title: "Hire me",
      link: "#kontak",
    },
  },
};