import {Database} from '../DB/Database.js';
import Resume from '../../attachments/Resume.pdf'
import { FaFileDownload } from 'react-icons/fa';
import { useEffect, useState } from 'react';

const Home = () => {

    var [highlight, setHighlight] = useState("");

    var array = ["Web Developer", "Designer", "Native Developer"];
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
          setHighlight(array[index]);
          setIndex((prevIndex) => (prevIndex + 1) % array.length);
        }, 3000);
        
        return () => {
             clearTimeout(timeout)
        };
    }, [highlight])



    const className = {
        container: "bg-purple-black w-full h-screen xl:h-full grid grid-cols-1 xl:grid-cols-2 gap-5",
        box: "h-full lg:pt-20 w-full flex flex-col justify-center items-start gap-8 pl-10",
        img: "hidden xl:block h-full w-full",
        paragraphBox: "text-white font-Poppins font-bold space-y-5 text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl",
        para2: "inline-flex items-center justify-center gap-3 ",
        highlight: "text-white-green",
        subPara: "text-white",
        button: "bg-white-green text-teks-black w-fit px-5 py-3 flex items-center gap-3"
    }

    const Data = Database.home

    return (
        <div className={className.container} id='home'>
            <div className={className.box}>
                <div className={className.paragraphBox}>
                    <h1>
                        {Data.headtitle1} 
                    </h1>
                    <h2 className={className.para2}>
                        <p>{Data.headtitle2}</p> <p className={className.highlight}>
                            {highlight}
                        </p>
                    </h2>
                </div>

                <div className={className.subPara}>
                    {Data.subtitle}
                </div>

                <a href={Resume} download="Adams-Resume" rel={"noreferrer"} target='_blank' className={className.button}>
                    {Data.button1} <FaFileDownload />
                </a>
            </div>
            
            <img src={Data.img} alt='hero img' className={className.img}/>
        </div>
    )

}

export default Home;