/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Database } from "./DB/Database";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from '../attachments/greenLogo.png';


export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setClicked] = useState('#');

  

  const className = {
    container: `fixed inset-x-0 font-Roboto px-10 py-5 top-0 flex items-center justify-between bg-light-black`,
    logo: "w-24 h-auto",
    menu: "space-x-7 flex items-center font-medium text-lg",
    desktop: "hidden lg:flex w-full items-center justify-between",
    mobile: "flex lg:hidden w-full items-center justify-between",
    list: `block w-fit`,
    button: "block w-fit px-8 py-3 bg-white-green font-medium text-light-black",
  };

  var Data = Database.navbar;

  return (
    <>
      <div>
        <header className={className.container}>
          <div className={className.desktop}>
            <img src={Logo} className={className.logo} />

            <ul className={className.menu}>
              {Data.menus.map((data, idx) => (
                <li key={idx}>
                  <a
                    className={`${className.list} ${
                      isClicked === data.link
                        ? "text-white-green"
                        : "text-white"
                    }`}
                    href={data.link}
                    onClick={() => setClicked(data.link)}
                  >
                    {data.title}
                  </a>
                </li>
              ))}
            </ul>

            <a href={Data.button.link} className={className.button}>
              {Data.button.title}
            </a>
          </div>

          <div className={className.mobile}>
            <img src={Logo} className={className.logo} />

            {!isOpen ? (
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="px-4 text-white font-bold text-xs rounded-md hover:shadow-md transform flex items-center gap-2"
              >
                  <FaBars className="text-xl" /> 
              </button>
            ) : (
              <button
                className="text-white hover:text-gray-200 transform duration-200 fixed top-8 right-8 text-xs z-10 flex gap-2 items-center"
                onClick={() => setIsOpen(!isOpen)}
              >
                <FaTimes className="text-xl" />
              </button>
            )}
          </div>

          

          <aside
            className={`inset-y-0 -left-1 fixed bg-purple-black shadow-xl shadow-gray-400 p-14 w-72 ${
              isOpen ? "translate-x-0" : "-translate-x-full"
            } duration-300 ease-in-out space-y-5`}
          >
            {Data.menus.map((data, index) => (
              <a
                href={data.link}
                onClick={() => {
                  setIsOpen(!isOpen);
                  setClicked(data.link);
                }}
                key={index}
              >
                <h3
                  className={`text-xl hover:text-white-green mb-5 transform duration-200 ${
                      isClicked === data.link
                        ? "text-white-green"
                        : "text-white"
                    }`}
                >
                  {data.title}
                </h3>
              </a>
            ))}

            {/* <Dropdown options={Data.DropdownData} /> */}
          </aside>
        </header>
      </div>
    </>
  );
}
